import { App } from '../../../shared/js/register.js';
import { start } from '../../../shared/js/init.js';

import translations from './translations.json';
import '../../../shared/js/phones.js';

document.addEventListener("DOMContentLoaded", function () {
    window.Form = new App({

        noResults: "\u0421\u043E\u0432\u043F\u0430\u0434\u0435\u043D\u0438\u0439 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E",
        messages: {
            required: '\u092b\u093c\u0940\u0932\u094d\u0921\u0020\u0915\u094b\u0020\u092d\u0930\u0947\u0902',
            phone: {
                incorrect: '\u092b\u093c\u094b\u0928\u0020\u0928\u0902\u092c\u0930\u0020\u0905\u092e\u093e\u0928\u094d\u092f\u0020\u0939\u0948'
            },
            email: {
                incorrect: '\u0917\u0932\u0924\u0020\u092a\u0924\u093e\u0020\u092a\u094d\u0930\u093e\u0930\u0942\u092a',
                symbolNotFound: '\u092a\u0924\u0947\u0020\u092e\u0947\u0902\u0020\u0935\u0930\u094d\u0923\u0020\u0022\u0040\u0022\u0020\u0939\u094b\u0928\u093e\u0020\u091a\u093e\u0939\u093f\u090f\u0964\u0020\u092a\u0924\u093e\u0020\u0022\u0040\u0022\u0020\u092a\u094d\u0930\u0924\u0940\u0915\u0020\u092f\u093e\u0926\u0020\u0906\u0020\u0930\u0939\u093e\u0020\u0939\u0948',
                addressIsNotFull: '\u0022\u0040\u0022\u0020\u0935\u0930\u094d\u0923\u0020\u0915\u0947\u0020\u092c\u093e\u0926\u0020\u092a\u0924\u0947\u0020\u0915\u093e\u0020\u0939\u093f\u0938\u094d\u0938\u093e\u0020\u0926\u0930\u094d\u091c\u0020\u0915\u0930\u0947\u0902\u0964\u0020\u092a\u0924\u093e\u0020\u092a\u0942\u0930\u093e\u0020\u0928\u0939\u0940\u0902\u0020\u0939\u0941\u0906\u0020\u0939\u0948'
            },
            password: {
                minLength: '\u0905\u0928\u0941\u092e\u0924\u0020\u0938\u093f\u092e\u094d\u092c\u094b\u0932\u0020\u0915\u0940\u0020\u0928\u094d\u092f\u0942\u0928\u0924\u092e\u0020\u0938\u0902\u0916\u094d\u092f\u093e: 6. \u0905\u092c\u0020\u0939\u0948: %current_length%'
            },
            repeatPassword: {
                discrepancy: '\u0921\u093e\u0932\u093e\u0020\u0917\u092f\u093e\u0020\u092a\u093e\u0938\u0935\u0930\u094d\u0921\u0020\u092e\u0948\u091a\u0020\u0928\u0939\u0940\u0902\u0020\u0915\u0930\u0020\u0930\u0939\u093e\u0020\u0939\u0948'
            },
            ofertaAgreement: {
                notChecked: '\u0928\u093f\u092f\u092e\u094b\u0902\u0020\u0915\u0940\u0020\u0905\u092a\u0928\u0940\u0020\u0938\u094d\u0935\u0940\u0915\u0943\u0924\u093f\u0020\u0915\u0940\u0020\u092a\u0941\u0937\u094d\u091f\u093f\u0020\u0915\u0930\u0947\u0902'
            },
        },

        options: {
            regType: "phone",
            bonusType: "casino",

            tabButtonClass: ".tab",
            formEmailClass: ".form-email",
            formPhoneClass: ".form-phone",
            buttonActionClass: ".button-action",
            emailInputClass: ".email-input",
            passwordInputClass: ".password-input",
            passwordInput2Class: ".password-input2",
            phoneInputClass: ".phone-input",
            emailCountry: ".email-country",
            phonePrefixSelect: ".phone-prefix__select",
            phonePrefixCode: ".phone-prefix__code",
            phoneCurrency: ".phone-currency",
            emailCurrency: ".email-currency",
            phonePrefixFlag: ".phone-prefix__flag",

            emailErrorClass: ".email-error",
            passwordErrorClass: ".password-error",
            password2ErrorClass: ".password2-error",
            phoneErrorClass: ".phone-error",
            ofertaAgreementInputId: "#oferta-agreement",
        },
        
    });

    start({ phones: window.phones, translations: translations });
});